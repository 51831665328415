var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3b74f4bbdf35746f25e126e08fd32a3672a2f284"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { db } from "./config/firebase1"
//@ts-ignore
import { OBJECT_VAL } from "./config/getchainid"
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

//@ts-ignore
const getchains: any[] = OBJECT_VAL;
const currentPath = window.location.pathname;

// Extract the value after the first slash
const firstSlashValue:any = currentPath.split('/')[1];
//@ts-ignore
// const chain = getchains[firstSlashValue];
// console.log('getchains', OBJECT_VAL)
// const chain = 1;
// console.log(firstSlashValue, chain)
Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.3,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay(),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['log', 'info', 'warn', 'error', 'debug', 'assert'],
    }),
  ],
  ignoreErrors: ['User rejected the request'],
  beforeSend(event) {
    try {
      // console.log('sandeep event',event?.message);
      // "Execute Results: posting results"
      if (Array.isArray(event?.extra?.arguments)) {
        //  console.log("sandeep event 1", event)
//         if (event?.extra?.arguments[1] == "ReservoirClient: Sending Event to 1 listeners"){
//  console.log('sandeep event 2', event?.extra?.arguments[2])
//       }
        if (event?.extra?.arguments[1] == "Execute Steps: Transaction step, got transactions"){
          console.log('sandeep event 3', JSON.stringify(event?.extra?.arguments[2][0]))


      }
        if (event?.extra?.arguments[1] == "Execute Results: posting results"){
 console.log('sandeep event 4', event?.extra?.arguments[2])
          console.log('sandeep event 4', event?.extra?.arguments[2]?.requestId)
          let requestId = event?.extra?.arguments[2]?.requestId;
          let stepId = event?.extra?.arguments[2]?.stepId;
          let txHash = event?.extra?.arguments[2]?.txHash;

          ///////////////////////////////////////////////hash insert///////////////////////////
          let currentTime = new Date();
          let formattedTime = currentTime.toISOString();
          db.collection('tableforhash').add({
            requestId: requestId,
            stepId: stepId,
            txHash: txHash,
            time: formattedTime,
            chainname: firstSlashValue,
            // Add more fields as needed
          }).then((docRef: { id: any }) => {
            console.log('Document written with ID: ', docRef.id);
            // Optionally, clear the form fields after submission

          })
            .catch((error: any) => {
              console.error('Error adding document: ', error);
            });



          ///////////////////////////////////////////////hash insert end///////////////////////////
      }
     
      }
      // //@ts-ignore
      // console.log('sandeep event 2', event?.extra?.argument)
      // //@ts-ignore
      // console.log('sandeep event 3', event?.extra?.argument)

///////////////////////////////////////////////////////////////////


//  Execute Steps: Steps retrieved 
////////////////////////////////////////////////////////
      // {
      //   "url": "http://localhost:3000/api/reservoir/sepolia/execute/mint/v1",
      //     "method": "post",
      //       "data": {
      //     "items": [
      //       {
      //         "collection": "0xb5e7041ca1b7024c77cedccb3da205a660643697",
      //         "quantity": 1
      //       }
      //     ],
      //       "taker": "0x2a055Bf95dBcDF55503AD30d6dad1012439b9BF1",
      //         "source": "localhost:3000",
      //           "partial": true,
      //             "currencyChainId": 11155111
      //   },
      //   "headers": {
      //     "x-rkui-version": "1.21.4",
      //       "x-rkc-version": "1.11.2"
      //   },
      //   "baseURL": "http://localhost:3000/api/reservoir/sepolia"
      // }
///////////////////////////////////////////////////////////////////////////////////////////////
      //Execute Steps: checking expected price expected price
      ////////////////////////////////////////////////////////////
  
      ////////////////////////////////////////////////////////////




      // "Execute Steps: all steps complete"
// Execute Steps: checking expected price expected price

      //"Execute Steps: Transaction step, got transactions"






      // "ReservoirClient: Sending Event to 1 listeners"











      //Ignore errors from chrome extension
      if (
        event.exception?.values &&
        event.exception.values[0].stacktrace &&
        event.exception.values[0].stacktrace.frames &&
        event.exception.values[0].stacktrace.frames[0].filename &&
        event.exception.values[0].stacktrace.frames[0].filename.includes(
          'app://inject'
        )
      ) {
        return null
      }
    } catch (e) {}

    return event
  },
})

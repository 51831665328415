import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASEapiKey,
  authDomain: "bv2a-37d51.firebaseapp.com",
  databaseURL: "https://bv2a-37d51-default-rtdb.firebaseio.com",
  projectId: "bv2a-37d51",
  storageBucket: "bv2a-37d51.appspot.com",
  messagingSenderId: "852061388684",
  appId: "1:852061388684:web:cde8fd2bccdc396032a0a9"
};

const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();
const OBJECT_VAL= [];

OBJECT_VAL["ethereum"]= 1;
OBJECT_VAL["arbitrum"]= 42161;
OBJECT_VAL["goerli"]= 5;
OBJECT_VAL["mumbai"]= 80001;
OBJECT_VAL["bsc"]= 56;
OBJECT_VAL["polygon"]= 137;
OBJECT_VAL["zora"]= 7777777;
OBJECT_VAL["optimism"]=  420;
OBJECT_VAL["optimism"]=  10;
  OBJECT_VAL["sepolia"]= 11155111;
  OBJECT_VAL["base"]= 8453;
  OBJECT_VAL["avalanche"]= 43114;
OBJECT_VAL["nova-arbitrum"]=42170;

export default OBJECT_VAL